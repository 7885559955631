
.bg-light{
    padding-bottom: 4rem;
    .containerHeader {
        padding-left: 18rem;
        padding-right: 18rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    
        h2{
            font-size: 2rem;
            font-weight: 700;
        }
        .containerLogo{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .social{
            display:flex;
            figure:nth-child(2){
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
    .containerSubStartup{
        padding: 2rem 1.5rem;
        border : solid #c7c5c5 1px;
        margin-left: 18rem;
        margin-right: 18rem;
        margin-top: 2rem;
        .columns {
            .textHeader{
                color : #62778C;
            }
        }
    }
    .containerSubStartupFounder{
        padding: 2rem 1.5rem;
        border : solid #c7c5c5 1px;
        margin-left: 18rem;
        margin-right: 18rem;
        margin-top: 2rem;
        .columns {
            .textHeader{
                color : #62778C;
            }
        }
        .boxes{
            display: flex;
            .subtitle{
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .imgFounder{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}
/* ==========================================================================
1. Tablet Portrait
========================================================================== */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    .bg-light{
        padding-bottom: 4rem;
        .containerHeader {
            padding-left: 4rem;
            padding-right: 4rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    
            h2{
                font-size: 2rem;
                font-weight: 700;
            }
            .containerLogo{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .social{
                display:flex;
                figure:nth-child(2){
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }
        .containerSubStartup{
            padding: 2rem 1.5rem;
            border : solid #c7c5c5 1px;
            margin-left: 4rem;
            margin-right: 4rem;
            margin-top: 4rem;
            .columns {
                .textHeader{
                    color : #62778C;
                }
            }
        }
        .containerSubStartupFounder{
            padding: 2rem 1.5rem;
            border : solid #c7c5c5 1px;
            margin-left: 4rem;
            margin-right: 4rem;
            margin-top: 2rem;
            .columns {
                .textHeader{
                    color : #62778C;
                }
            }
            .boxes{
                display: flex;
                .subtitle{
                    padding-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .imgFounder{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}

/* ==========================================================================
2. Mobile
========================================================================== */

@media (max-width: 767px) {
    .bg-light{
        padding-bottom: 4rem;
        .containerHeader {
            padding-left: 2rem;
            padding-right: 2rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    
            h2{
                font-size: 2rem;
                font-weight: 700;
            }
            .containerLogo{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .social{
                display:flex;
                figure:nth-child(2){
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }
        .containerSubStartup{
            padding: 2rem 1.5rem;
            border : solid #c7c5c5 1px;
            margin-left: 2rem;
            margin-right: 2rem;
            margin-top: 2rem;
            .columns {
                .textHeader{
                    color : #62778C;
                }
            }
        }
        .containerSubStartupFounder{
            padding: 2rem 1.5rem;
            border : solid #c7c5c5 1px;
            margin-left: 2rem;
            margin-right: 2rem;
            margin-top: 2rem;
            .columns {
                .textHeader{
                    color : #62778C;
                }
            }
            .boxes{
                display: flex;
                .subtitle{
                    padding-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .imgFounder{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}