/*! _colors.scss | Krypton | Kit 1 | CSS Ninja */

/* ==========================================================================
Krypton global colors 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Variables
=============================================================================
***/

/* ==========================================================================
0. Variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$placeholder: #cecece;
$light-grey: #f9f9f9;
$muted-grey: #999;
$title-grey: #A9ABAC;
$fade-grey: #ededed;
$accent-grey: #ccc;
$dark-text: #4a4a4a;
$chosen-grey: #e0e0e0;
$chosen-dark-grey: #66676b;
$grey-6: #666;
$grey: #ccc;
$section-grey: #fcfcfc;
$light-blue: rgba(94,147,255,.9);

$sidebar: #34495e;
$red: #FF7273;
$blue: #34495e;
$green: #00c3c0;
$orange: #ff8602;