.button.k-primary.has-gradient {
  position: relative;
  transition: all 0.6s ease-out !important;
  border: none !important;
  background: #00c3c0;
  opacity: 1;
  z-index: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.brand-name {
  background-image: url(../images/ev2019/logo-f319.png);
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -130px;
}

.hero #particles-js {
  position: absolute;
  top: 12%;
  left: 45%;
  width: 40%;
  height: 40%;
  z-index: 0;
}

.button.k-primary.has-gradient .front-gradient {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 8px;
  letter-spacing: 0.02857em;
}

.beta {
  color:$orange;
  font-size: 0.75em;
  margin-left: -25%;
  padding-top: 15%;
}
.is-dosis {
  font-family : $primary-font!important;
}
.is-opensans {
  font-family : $secondary-font;
}
.is-spacing {
  letter-spacing:1px;
}
.is-halflucid
{opacity: 0.5;}
.is-nearlucid
{opacity:0.8;}
.divider-nograd {
  background-color: #00c3a0!important;
  height: 3px;
  width: 75px;
}
.p_b-48
{padding-bottom: 48px;}
.for-biz
{font-family: Dosis;
  padding-top: 20px;
  margin-left: -80px;
  font-size: 0.75em;
}
.margin-auto
{
  margin : auto;
}
.dark-text
{color: $tertiary!important;
  
    h4 {
      padding-left: 10px;
      border-left: 1px solid #2a927e;
      }
  
}
.auto-h
{height: auto!important;}
.no-border
{border:none!important;}
.no-box
{box-shadow: none!important;}
.no-bg
{background: none!important}
.no-padding
{padding:0px!important;}
.modal-video {
  background-image:  url('../images/ev2019/foundpad-video.svg');
  background-repeat: no-repeat;
  background-size: 85%;
  background-position-x: 50%;
  background-position-y: 15%;
}
.modal-video-movie-wrap{
background-color: #053c6080!important;
}
.advisor-container img {
  height: 57px!important;
  width: 57px!important;
  border-radius:0!important;
}
.label-login {
  color:$primary-accent;   
}
.fieldset-login {
  border:none;
}
.fieldset-apply {
  border:none;
}
.error-message
{
  color: $secondary-accent;
}
.is-hidden {
  display:none!important;
}
.help-login, .radio-login {
  color:#9ca6b0!important;
}
.button-i 
{vertical-align: middle;
  padding-bottom: 1px;
  width: 12px;
  height: 12px;}

  .button-i-medium 
{vertical-align: middle;
  padding-bottom: 1px;
  width: 14px;
  height: 14px;
  margin-left: 2px;}
  .is-not-hidden {
    display: block;
  }

  .modal-overlay1 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    transition: opacity 200ms;
    visibility: hidden;
    opacity: 0;
    &.light {
      background: rgba(255,255,255,0.5);
    }
    .cancel1 {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: default;
    }
    &:target {
      visibility: visible;
      opacity: 1;
    }
  }

  .modal-popup1 {
    margin: 75px auto;
    padding: 20px;
    background: #ffffffe6;
    border: 1px solid #666;
    width: 45%;
    box-shadow: 0 0 50px rgba(0,0,0,0.5);
    border-radius: 6px;
    position: relative;
    top:30%;
    .light1 & {
      border-color: #aaa;
      box-shadow: 0 2px 10px rgba(0,0,0,0.25);
    }
    h2 {
      margin-top: 0;
      color: #666;
      font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
    }
    .modal-close1 {
      position: relative;
      width: 20px;
      height: 20px;
      top: 100%;
      left: 100%;
      opacity: 0.8;
      transition: all 200ms;
      font-size: 24px;
      font-weight: bold;
      text-decoration: none;
      color: #666;
      &:hover {
        opacity: 1;
      }
    }
    .modal-content1 {
      max-height: 400px;
      overflow: auto;
    }
    p {
      margin: 0 0 1em;
      &:last-child {
        margin: 0;
      }
    }
  }
  .is-visible {
    visibility: visible;
    z-index:99999;
    opacity:1;
  }
  .is-not-visible {
    visibility: hidden;
    opacity:0;
    z-index: 0;
  }
  .is-right
  {float:right;}