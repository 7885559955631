


/* ==========================================================================
1. Tablet  between
========================================================================== */

@media only screen and (min-device-width : 768px) and (max-device-width : 1200px) {
  .laureats{
    padding-top: 3rem;
  }
  .containerStartup {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7rem 3rem 0rem 3rem;
    .card {
      height: 200px;
      box-shadow: none;
      flex: 1 0 50%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-image-wrapper {
        min-height: 40px;
        .card-image {
          .plus {
            display: none;
          }
          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          &:hover {
            background-color: #003c60;
            transition: 1s;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
            position: absolute;
            img {
              display: none;
            }
            .logo {
              display: hidden;
              transition: 1s;
            }
            .plus {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              a{
                font-size: 1.3rem;
                font-weight: bold;
                color: white;
              }
            }
          }
          border-radius: 20px;
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        border-radius: 0 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        h3 {
          font-weight: 800;
          color: #221638;
          font-size: 27px;
        }
      }
    }
  }
}

/* ==========================================================================
1. Tablet or Phone between
========================================================================== */

@media only screen and (min-device-width : 768px) and (max-device-width : 900px) {
  .laureats{
    padding-top: 3rem;
  }
  .containerStartup {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7rem 3rem 0rem 3rem;
    .card {
      height: 200px;
      box-shadow: none;
      flex: 1 0 100%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-image-wrapper {
        min-height: 40px;
        .card-image {
          .plus {
            display: none;
          }
          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          &:hover {
            background-color: #003c60;
            transition: 1s;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
            position: absolute;
            img {
              display: none;
            }
            .logo {
              display: hidden;
              transition: 1s;
            }
            .plus {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              a{
                font-size: 1.5rem;
                font-weight: bold;
                color: white;
              }
            }
          }
          border-radius: 20px;
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        border-radius: 0 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        h3 {
          font-weight: 800;
          color: #221638;
          font-size: 27px;
        }
      }
    }
  }
}

/* ==========================================================================
1. Phone max device screen 768px
========================================================================== */

@media only screen and (max-device-width : 768px) {
  .laureats{
    padding-top: 3rem;
  }
  .containerStartup {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7rem 3rem 0rem 3rem;
    .card {
      height: 200px;
      box-shadow: none;
      flex: 1 0 100%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-image-wrapper {
        min-height: 40px;
        .card-image {
          .plus {
            display: none;
          }
          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          &:hover {
            background-color: #003c60;
            transition: 1s;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
            position: absolute;
            img {
              display: none;
            }
            .logo {
              display: hidden;
              transition: 1s;
            }
            .plus {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              a{
                font-size: 1rem;
                font-weight: bold;
                color: white;
              }
            }
          }
          border-radius: 20px;
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        border-radius: 0 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        h3 {
          font-weight: 800;
          color: #221638;
          font-size: 27px;
        }
      }
    }
  }
}

/* ==========================================================================
1. Desktop 1920px
========================================================================== */

@media only screen and (min-device-width : 1200px) and (max-device-width : 1920px) {
  .laureats{
    padding-top: 3rem;
  }
  .containerStartup {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 7rem 3rem 0rem 3rem;
    .card {
      height: 200px;
      box-shadow: none;
      flex: 1 0 33%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-image-wrapper {
        min-height: 40px;
        .card-image {
          .plus {
            display: none;
          }
          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          &:hover {
            background-color: #003c60;
            transition: 1s;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
            position: absolute;
            img {
              display: none;
            }
            .logo {
              display: hidden;
              transition: 1s;
            }
            .plus {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              a{
                font-size: 1.5rem;
                font-weight: bold;
                color: white;
              }
            }
          }
          border-radius: 20px;
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        border-radius: 0 0 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        h3 {
          font-weight: 800;
          color: #221638;
          font-size: 27px;
        }
      }
    }
  }
}